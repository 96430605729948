import SocialLinks from '../components/socialLinks';
import { footercss } from './styles';

const Footer = () => {
  return (
    <div style = {footercss} >
      <SocialLinks/>
      <ul className="icons">
        <li>&copy;iawale</li>
      </ul>
    </div>
  );
};

export default Footer;
