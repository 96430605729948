import React from "react";
import parse from "html-react-parser";
import {addBR} from '../common/utils'

function Heading(props) {
  return (
    <header>
      <h1>{props.header}</h1>
      <h4>
       {parse(addBR(props.subHeader))}
      </h4>
    </header>
  );
}

export default Heading;
