import React from "react";
import { Link } from "react-router-dom";
import { addBR } from "./utils";
import parse from "html-react-parser"
import { toast } from "react-toastify";

const Spotlight = (props) => {
  let project = props.project;
  let position = project.id % 2 === 0? "even" : "odd";
  let accent = project.id % 2 === 0? "accent2" : "accent3";
  let link = project.link.trim() === "" ? "#" : project.link;
  function notifyToast() {
    toast.dark("☕️ Apologies! the page is under construction!");
  }
  return (
    <>
      <article id="two" className={"spotlight " + accent + " " + position}>
        <div class="inner">
          <div class="image" data-position="top left">
            {/* <img src="images/pic02.jpg" alt="" /> */}
          </div>
          <h2>
            {parse(addBR(project.heading,5))}
          </h2>
          <div class="content">
            <h4>
              {parse(addBR(project.subHeading, 10))}
            </h4>
            <br/>
            <p>
              {project.description}
            </p>
            <br/>
            <ul class="actions">
              <li>
                <Link to={link} class="button mediium" onClick={notifyToast}>
                  Learn more
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </>
  );
};

export default Spotlight;
