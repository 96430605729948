import Spotlight from '../common/spotlightBlock';
const ProjectList = (props) => {
  return (
    <>
      {props.projects.map((x) => (
        <Spotlight project={x}></Spotlight>
      ))}
    </>
  );
};

export default ProjectList;
