import React, { Component } from "react";
import { Link } from "react-router-dom";
import Heading from "../common/heading";
import { Card } from "react-bootstrap";
import { styles } from "../common/styles";
import { toast } from "react-toastify";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Chirag Awale",
      subHeader: "Full Stack Engineer",
    };
  }
  render() {
    let email = "chiragawalep@gmail.com";
    function notifyToast() {
      navigator.clipboard.writeText(email);
      toast.dark("✍️ The email has been copied to clipboard.");
    }
    return (
      <Card style={styles.custompanel}>
        <article id="home" className="special">
          <div className="content">
            <div className="inner">
              <Heading
                header={this.state.name}
                subHeader={this.state.subHeader}
              />
              <nav id="nav">
                <ul className="actions stacked special animated spinY">
                  <li>
                    <Link to="/work" className="button">
                      Work
                    </Link>
                  </li>
                  {/* <li><Link to="/about" className="button">About</Link></li> */}
                  {/* <li>
                    <Link to="/projects" className="button">
                      Projects
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/" className="button" onClick={notifyToast}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </article>
      </Card>
    );
  }
}

export default Home;
