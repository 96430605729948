export function addBR (text, maxLen = 30) {
  if (text === undefined) return "::";
  let result = "";
  let count = 0;
  if (text.length > maxLen) {
      text.split(" ").forEach((str) => {
        if (count > maxLen) {
          result = result + " <br /> " + str;
          count = 0;
        } else {
          result = result + " " + str;
          count = count + str.length;
        }
      });
  } else {
    return text;
  }
  return result;
}
