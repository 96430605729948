import { Navbar, Nav } from "react-bootstrap";

const NavApp = () => {
  return (

  <Navbar sticky="top" bg="white" variant="light" className="justify-content-end" activeKey="/home" >
    <Nav >
      <Nav.Link href="/" >Home</Nav.Link>
      <Nav.Link href="/work">Work</Nav.Link>
      {/* <Nav.Link href="/projects">Projects</Nav.Link> */}
      {/* <Nav.Link href="/">Contact</Nav.Link> */}
    </Nav>
  </Navbar>
  
  );
};

export default NavApp;
