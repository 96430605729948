
const BackButton = () => {
  return (
  <> 
    {/* <ul className="actions animated spinX">
      <li>
        <Link to="/" className="button small back">
          Back
        </Link>
      </li>
    </ul> */}
   </>
  );
};

export default BackButton;
