import React, { Component } from "react";
import Heading from "../common/heading";
import { Card } from "react-bootstrap";
import { styles } from "../common/styles";
import Block from "../common/block";
import BackButton from "../common/back";
export default class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Work Experience",
      subHeader: "Full Stack Engineer",
      items: [
        {
          heading: "Full Stack Engineer",
          company: "The Home Depot",
          start: "Feb 2022",
          end: "Present",
          points: [
            	"Revolutionized the product return process by designing and developing Spring Boot microservices, reducing process completion time significantly.",
            	"Spearheaded the migration of our pipelines from TeamCity to GitHub Actions and contributed to the development of reusable workflows for greater efficiency, and consistency across applications and teams.",
              "Created intuitive user-friendly Angular Components, utilizing Angular, TypeScript, HTML, and CSS, resulting in reduced error rates, increased productivity, and improved overall user experience.",
              "Implemented secure secrets management by configuring applications to interact with Vault, ensuring the protection of sensitive information",
              "Optimized data handling in back-end applications by modeling entities and writing efficient queries for PostgreSQL.",
              "Ensured code quality and reliability by writing comprehensive unit tests using Jasmine and Karma for Angular and Junit, Mockito, and Groovy for Java.",
              "Implemented effective debugging processes using Log4j for clear and concise logging, improving process clarity and efficiency.",
              "Containerized applications with Docker, managed CI/CD pipelines in TeamCity, and deployed applications to PCF.",
              "Researched various applications to formulate work plans for further development of the applications.",
              "Implemented design patterns to improve applications’ scalability, robustness, clarity, and flexibility.",
          ],
        },
        {
          heading: "Java Full Stack Developer",
          company: "Citi",
          start: "Nov 2020",
          end: "Jan 2022",
          points: [
            "Designed and implemented solutions in Spring Boot Microservices using Java, Spring Boot, Spring Data JPA, and Eclipse IDE.",
            "Developed solutions in monolith applications while improving maintainability, readability, and efficiency.",
            "Designed and implemented efficient queries to optimize data retrieval and processing using Java, Spring Boot, Spring JPA, Sybase, JSON, and Rest API.",
            "Developed and maintained presentation layers of applications using Angular, HTML, and CSS.",
            "Involved in upgrading application dependencies such as Axis while improving code maintainability and readability while resolving application vulnerabilities.",
            "Engineered solution that eliminated 1-2 weeks of processing time for handling crucial user onboarding and maintenance operations utilizing Java, Spring boot, Spring JPA, and Hibernate in a microservices oriented architecture with Rest and SOAP-based APIs.",
            "Analyzed and consolidated pending issues and tasks and prepared daily reports to provide to all stakeholders for smoother tracking and improved productivity.",
          ],
        },
        {
          heading: "Java Developer",
          company: "Kodiary Technology",
          start: "Dec 2019",
          end: "Nov 2020",
          points: [
            "Built retail store website utilizing React as frontend framework, Java with Spring boot as the backend, Oracle as the database, RabbitMQ and REST for asynchronous and synchronous communication.",
            "Designed and developed School Management System to help schools and colleges function smoothly during COVID-19 lockdown utilizing the microservice architecture using Java, Spring Boot, Spring Data JPA, Spring AOP, Spring Web, Hibernate, Oracle 11g, REST, Lambda Expressions, Angular 7.",
            "Migrated SOAP to Restful Web Services, designing the endpoints for sending and retrieving data for efficiency, and maintainability.",
          ],
        },
        {
          heading: "Software Engineer Intern",
          company: "Open Learning Exchange",
          start: "Jun 2019",
          end: "Dec 2019",
          points: [
            "Developed different features in Android Application utilizing views such as Recycler view, Adapters, and cursors, supporting ease of use, efficient functionality and also supporting multiple locales while adhering to quality standards and delivery dates.",
            "Involved in writing unit tests using Junit and Mockito for improving robustness and stability of the application and performed code reviews of peers.",
            "Involved in providing bug fixes which were tracked through GitHub Issues.",
          ],
        },
      ],
    };
  }
  render() {
    return (
      <Card style={styles.custompanel}>
        <article id="home" className="special">
          <div className="content">
            <BackButton/>
            <div className="inner">
              <Heading
                header={this.state.name}
                subHeader={this.state.subHeader}
              />
              {this.state.items.map((x) => (
                <Block key={x.company + x.heading} item={x}></Block>
              ))}
            </div>
          </div>
        </article>
      </Card>
    );
  }
}
