import React, { Component } from "react";

class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let item = this.props.item;
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col"><h3>{item.heading},{item.company}</h3> <h4> {item.start} to{" "}
            {item.end === "" || item.end === undefined ? "present" : item.end}</h4></th>
            </tr>
          </thead>
          <tbody>
            {item.points.map(x => 
                    <tr key={x}><td>{x}</td></tr>
                )}
          </tbody>
        </table>
      </>
    );
  }
}

export default Block;
