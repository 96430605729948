import React, { Component } from "react";
import "../assets/css/spotlight.css";
import ProjectList from "./projectList";
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects : [
        {
          id : '1',
          heading: 'NFT Hub',
          subHeading: 'Research wallets, analyze trends, collect, invest or sell.',
          description: 'Developing a application utilizing Web 3.0 to make it easier to research NFTs and price trends.',
          link: ''
        },
        {
          id : '2',
          heading: 'HUNGRY PEOPLE',
          subHeading: 'Start reaching customers online',
          description: 'Developed an application aimed at providing local stores and restaurants in Nepal an easy-to-use order management system utilizing Java, Spring Boot, Spring Data JPA, PostgreSQL, React, and REST services.',
          link: ''
        },
      ]
    };
  }
  render() {
    return (
      <>
        <ProjectList projects = {this.state.projects}/>
      </>
    );
  }
}

export default Projects;
