export var styles = {
    custompanel: {
      padding: "5%", 
      background: "white", 
      "min-height": "100%",
      height: "auto",
      border: "0px"
    }
}
export var projects = {
  custombulk: {
    margin: 0,
    padding: 0,
    border: 0,
    "font-size": "100%",
    font: "inherit",
    verticalalign: "baseline"
  }
}

export var footercss = {
 "text-align" : "center", 
}
