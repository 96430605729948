import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SocialLinks = () => {
  let email = "chiragawalep@gmail.com";
  function notifyToast() {
    navigator.clipboard.writeText(email);
    toast.dark("✍️ The email has been copied to clipboard.");
  }
  return (
    <ul className="icons">
      <li>
        <Link to={{ pathname: "https://www.linkedin.com/in/iawale/" }} target="_blank" className="icon brands fa-linkedin">
          <span className="label">LinkedIn</span>
        </Link>
      </li>
      <li>
        <Link to={{ pathname: "https://github.com/iawale" }} target="_blank" className="icon brands fa-github">
          <span className="label">GitHub</span>
        </Link>
      </li>
      <li>
        
        <Link to="#" className="icon solid fa-envelope" onClick={notifyToast}>
          <span className="label">Email</span>
        </Link>
      </li>
    </ul>

  );
};

export default SocialLinks;
