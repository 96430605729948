import "./App.css";
import Home from "./components/home";
import Resume from "./components/resume";
import { Route, Switch } from "react-router-dom";
import "./assets/css/main.css";
import "./assets/css/noscript.css";
import Work from "./components/work";
import Projects from "./components/projects";
import "bootstrap/dist/css/bootstrap.min.css";
import NavApp from "./components/nav";
import Footer from "./common/footer";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, cssTransition } from "react-toastify";

const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut"
});

function App() {
  return (
    <div className="App site">
      <NavApp />
      <ToastContainer 
          autoClose={2000}
          transition={bounce} 
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick ={false} />
      <div class = "site-content">
      <Switch>
        <Route path="/resume" exact component={Resume} />
        <Route path="/work" exact component={Work} />
        {/* <Route path="/contact" exact component={Contact} /> */}
        <Route path="/projects" exact component={Projects} />
        <Route path="/" exact component={Home} />
        <Route path="**" component={Home} />
      </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
